import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import {ApmService, ApmErrorHandler, ApmModule} from '@elastic/apm-rum-angular';
import { ErrorHandler } from '@angular/core';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
// import { QRScanner } from '@ionic-native/qr-scanner/ngx';
import { APP_INITIALIZER } from '@angular/core';
import { BrandsService} from './services/brands/brands.service';
// import { SplashScreen } from '@ionic-native/splash-screen/ngx';
// import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { ComponentsModule} from './components/components.module';
import { RestangularModule } from 'ngx-restangular';
import { Router } from '@angular/router';
import {AppSettings} from './app.settings';
import {ApiService} from './services/api/api.service';
import { BrowserAnimationsModule} from '@angular/platform-browser/animations';

import { Ionic4DatepickerModule } from '@logisticinfotech/ionic4-datepicker';
import {HttpClientModule, HTTP_INTERCEPTORS, HttpClient} from '@angular/common/http';
import {HttpCacheService} from './services/http-cache/http-cache.service';
import {CachingInterceptor} from './interceptors/http-interceptor';
// import {Contacts} from '@ionic-native/contacts/ngx';
import {OverlayModule} from '@angular/cdk/overlay';
// import {GeofenceService} from './services/geofence/geofence.service';
// import { Camera } from '@ionic-native/camera/ngx';
import {MatNativeDateModule} from '@angular/material/core';
import {AmplifyAuthenticatorModule} from '@aws-amplify/ui-angular';
import {Amplify} from 'aws-amplify';

function RestangularConfigFactory (RestangularProvider, router ) {
  function repeatRequest(response, subject, responseHandler) {
    setTimeout(_ => {
      response.repeatRequest(response.request).subscribe(
          res => { ApiService.hideWaiting(); responseHandler(res); },
          err => {
            if (!navigator.onLine) {
              repeatRequest(response, subject, responseHandler);
            } else {
              subject.error(err);
              ApiService.spinnerDecrement();
              ApiService.hideWaiting();
            }
          }
      );
    }, 1000);
  }
  RestangularProvider.setBaseUrl(AppSettings.API_ENDPOINT);
  RestangularProvider.addErrorInterceptor((response, subject, responseHandler) => {
    ApiService.spinnerDecrement();
    if (!navigator.onLine) {
      // no network connection
      ApiService.showWaiting('Oops! Looks like we are experiencing some issues. We’re working to solve the problem ASAP. Please check back soon!');
      repeatRequest(response, subject, responseHandler);
      return false;
    }
    if (response.status === 401 && response.data.errors !== 'Disabled user.') {
      if (response.data.error === 'Not trusted device!') {
        const userEmail: string = response?.request?.body?.username;
        router.navigate(['2fa/options', userEmail]);
        return false;
      } else {
        router.navigate(['']);
      }
      return false;
    }  else {
      return true;
    }
  });

  RestangularProvider.addResponseInterceptor((data, operation, what, url, response) => {
    ApiService.spinnerDecrement();
    ApiService.hideWaiting();
    return data;
  });

  RestangularProvider.setRequestInterceptor(function(elem, operation) {
    ApiService.spinnerIncrement();
    return elem;
  });

}

export function appInit(appConfigService: BrandsService) {
  return () => appConfigService.init();
}

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AmplifyAuthenticatorModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    ComponentsModule,
    HttpClientModule,
    Ionic4DatepickerModule,
    MatNativeDateModule,
    RestangularModule.forRoot([Router], RestangularConfigFactory),
    OverlayModule,
    ApmModule
  ],
  providers: [
    {provide: RouteReuseStrategy, useClass: IonicRouteStrategy},
    HttpCacheService,
    {provide: HTTP_INTERCEPTORS, useClass: CachingInterceptor, multi: true},
    BrandsService,
    {
      provide: APP_INITIALIZER,
      useFactory: appInit,
      deps: [BrandsService, HttpClient],
      multi: true
    },
    ApmService,
    {
      provide: ErrorHandler,
      useClass: ApmErrorHandler
    }
  ],
  bootstrap: [AppComponent],
    schemas: [ CUSTOM_ELEMENTS_SCHEMA]
})

export class AppModule {}
