import { Component, ViewChild,  OnInit, OnChanges, SimpleChanges, forwardRef, Input, Output, EventEmitter, ChangeDetectorRef, getDebugNode } from '@angular/core';
import {Platform} from '@ionic/angular';
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  NG_VALIDATORS,
  FormGroup,
  FormControl,
  Validator,
  Validators,
  AbstractControl,
  ValidationErrors
} from '@angular/forms';
import { errors } from './dob-errors';
import * as moment from 'moment/moment';
import {FormfieldbaseComponent} from '../formfieldbase/formfieldbase.component';

export let defaultMonth = '';
export let defaultDay = '';
export let defaultYear = '';
export let dob = '';

export function ValidateDay(control: AbstractControl) {
  const days = ['01', '02', '03', '04', '05', '06', '07', '08', '09'];
  let daysNumbers = [];
  if ( defaultYear === '' && defaultMonth === '' ) {
    daysNumbers = [];
    for (let i = 1; i <= 31; i++) {
      daysNumbers.push(i.toString());
    }
  }
  if ( defaultYear === '' && defaultMonth === '02' ) {
    daysNumbers = [];
    for (let i = 1; i <= 29; i++) {
      daysNumbers.push(i.toString());
    }
  }
  if ( defaultYear === '' && ( defaultMonth === '04' || defaultMonth === '06' || defaultMonth === '09' || defaultMonth === '11') ) {
    daysNumbers = [];
    for (let i = 1; i <= 30; i++) {
      daysNumbers.push(i.toString());
    }
  }
  if ( defaultYear === '' &&
  ( defaultMonth === '01'
  || defaultMonth === '03'
  || defaultMonth === '05'
  || defaultMonth === '07'
  || defaultMonth === '08'
  || defaultMonth === '10'
  || defaultMonth === '12') ) {
    daysNumbers = [];
    for (let i = 1; i <= 31; i++) {
      daysNumbers.push(i.toString());
    }
  }
  if ( defaultYear !== '' && defaultMonth !== '' ) {
    daysNumbers = [];
    const nrOfDays = moment(defaultYear + '-' + defaultMonth, 'YYYY-MM').daysInMonth();
    if (nrOfDays > 0) {
      for (let i = 1; i <= nrOfDays; i++) {
        daysNumbers.push(i.toString());
      }
    }
  }
  if ( days.includes(control.value) || daysNumbers.includes(control.value) ) {
    return null;
  } else {
    return { invalidDay: true, day: control.value };
  }
}

export function ValidateMonth(control: AbstractControl) {
  const months = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];
  if ( months.includes(control.value) ) {
    return null;
  } else {
    return { invalidMonth: true };
  }
}

export function ValidateYear(control: AbstractControl) {
  const years = [];
  for (let i = 1920; i <= parseInt(moment().format('YYYY'), 10); i++) {
    years.push(i.toString());
  }
  if ( years.includes(control.value) ) {
    return null;
  } else {
    return { invalidYear: true };
  }
}

const setTimeoutPromise = ((timeout: number) => new Promise(resolve => {
  setTimeout(resolve, timeout);
}));

@Component({
  selector: 'app-dob-picker',
  templateUrl: './dob-picker.component.html',
  styleUrls: ['./dob-picker.component.scss'],
  providers: [
              {
                  provide: NG_VALUE_ACCESSOR,
                  useExisting: forwardRef(() => DobPickerComponent),
                  multi: true
              },
              {
                provide: NG_VALIDATORS,
                useExisting: forwardRef(() => DobPickerComponent),
                multi: true
              }
            ]
})
export class DobPickerComponent extends FormfieldbaseComponent implements OnInit, OnChanges, ControlValueAccessor, Validator {

  @Input() getDob: string;
  @Input() clearAllowed = false;
  @ViewChild('day', {static: false}) inday;
  @ViewChild('month', {static: false}) inmonth;
  @ViewChild('year', {static: false}) inyear;

  private interface = 'alert';
  private mode = 'md';
  private labelChange = 'floating';
  private yearMax = parseInt(moment().format('YYYY'), 10); // current year as integer
  private yearMin = 1920;

  // errors
  public invalidMonth: boolean;
  public invalidDay: boolean;
  public invalidYear: boolean;

    registerOnChange(fn: any): void {
        this.fieldForm.valueChanges.subscribe(fn);
    }

  validate(c: AbstractControl): ValidationErrors | null {
    const controls = this.fieldForm.controls;
      Object.keys(controls).forEach(
        (controlName) => {
          if (controls[controlName].errors !== null) {
            this.required = controls[controlName].errors.hasOwnProperty('required');
            this.invalid = controls[controlName].errors.hasOwnProperty('invalid');
            this.invalidDay = controls[controlName].errors.hasOwnProperty('invalidDay');
            this.invalidMonth = controls[controlName].errors.hasOwnProperty('invalidMonth');
            this.invalidYear = controls[controlName].errors.hasOwnProperty('invalidYear');
          } else {
            this.required = false;
            this.invalid = false;
            this.invalidDay = false;
            this.invalidMonth = false;
            this.invalidYear = false;
          }
        });
       let message = '';
       if (this.required) { message = errors.required; }
       if (this.invalid) { message = errors.invalid; }
       if (this.invalidDay) { message = errors.dayError; }
       if (this.invalidMonth) { message = errors.monthError; }
       if (this.invalidYear) { message = errors.yearError; }
       if (this.invalid && this.required) { message = errors.invalid; }
       this.errorMessage = message;
    return this.fieldForm.valid ? null : { invalidForm: {valid: false, message: message}};
  }

  constructor(private cdRef: ChangeDetectorRef, private platform: Platform) {
    super('');
    this.fieldForm = new FormGroup(
      {
        monthField: new FormControl(''),
        dayField: new FormControl(''),
        yearField: new FormControl('')
      });
   }

  isFloatOrInt(n) {
    return !isNaN(n) && n.toString().match(/^-?\d*(\.\d+)?$/);
  }

  leapyear(year: number): boolean {
    return year % 100 === 0 ? year % 400 === 0 : year % 4 === 0;
  }

  validateDate(date: string): boolean {
    // must be YYYY-MM-DD
    if (date !== '') {
      const validDate = moment(date, 'YYYY-MM-DD', true).isValid();
      return validDate;
    }
    return false;
  }

  dayLostFocus(event) {
    this.blurInput(event)
    const day = event.target.value;
    if (day !== '' && parseInt(day, 10) < 10 && parseInt(day, 10) > 0 ) {
      this.setDay('0' + parseInt(day, 10));
    }
    if (day !== '' && ( parseInt(day, 10) === 0 || day === '00') ) {
      this.setDay('');
    }
  }

  ngOnInit() {
    if (this.platform.is('ios') || this.platform.is('ipad') || this.platform.is('iphone')) {
      this.interface = 'action-sheet';
      this.mode = 'ios';
    } else {
      this.interface = 'alert';
      this.mode = 'md';
    }
    if ( this.fieldForm.get('monthField').value !== ''
    || this.fieldForm.get('dayField').value !== ''
    || this.fieldForm.get('yearField').value !== '') {
      this.labelChange = 'stacked';
    } else {
      this.labelChange = 'floating';
    }
  }

  dateNotBiggerThanToday(date: string): boolean {
    const today = moment().format('YYYY-MM-DD');
    return !moment(date, 'YYYY-MM-DD').isAfter(moment(today, 'YYYY-MM-DD'));
  }

  ngOnChanges(changes: SimpleChanges) {
    if ( this.fieldForm.get('monthField').value !== ''
    || this.fieldForm.get('dayField').value !== ''
    || this.fieldForm.get('yearField').value !== '') {
      this.labelChange = 'stacked';
    } else {
      this.labelChange = 'floating';
    }

    if (changes.requiredDob && changes.requiredDob.isFirstChange()) {
      this.requiredField = changes.requiredDob.currentValue;
      defaultMonth = '';
      defaultYear = '';
    }
    this.fieldForm.valueChanges.subscribe((val) => {
      // form month is select and always have ok value
      if (val.monthField && val.monthField !== '') {
        this.labelChange = 'stacked';
        defaultMonth = val.monthField;
        // this.inday.setFocus();
      } else {
        defaultMonth = '';
      }

      // test year biger than and smaller than
      if (val.yearField && val.yearField !== '') {
        this.labelChange = 'stacked';
        if (val.yearField.length === 4) { // length of full year in input
          const selectedYear = parseInt(val.yearField, 10);
          if ( selectedYear < this.yearMin) {
            this.setYear(''); // default null
            defaultYear = '';
          } else if ( selectedYear > this.yearMax ) {
            this.setYear(''); // default null
            defaultYear = '';
          } else { // ok
            // this.inday.setFocus();
            defaultYear = val.yearField;
          }
        }
      } else {
        defaultYear = '';
      }

      // validate day
      if (val.dayField && val.dayField !== '') {
        this.labelChange = 'stacked';
        const dMonth = defaultMonth;
        const dYear = defaultYear;
        let daysInMonth = 31;
        if ( dYear === '' && dMonth === '02') { // special case
          daysInMonth = 29; // initial as for leap year
        }
        if ( dYear === '' && ( dMonth === '04' || dMonth === '06' || dMonth === '09' || dMonth === '11') ) { // months with 30 days
          daysInMonth = 30; // initial
        }
        if (dMonth !== '' && dYear !== '') { // get nr days for this month
          daysInMonth = moment(dYear + '-' + dMonth, 'YYYY-MM').daysInMonth();
        }
        if (parseInt(val.dayField, 10) < 0 || parseInt(val.dayField, 10) > daysInMonth) {
          this.fieldForm.get('dayField').setValue('', {emitEvent: false });
        } else if (parseInt(val.dayField, 10) < 0
        && parseInt(val.dayField, 10) < 10
        && val.dayField.length === 2
        && val.dayField !== '00') {
          this.fieldForm.get('dayField').setValue('0' + parseInt(val.dayField, 10), {emitEvent: false });
        } else if (val.dayField.length === 2 && val.dayField === '00') {
          this.fieldForm.get('dayField').setValue('', {emitEvent: false });
        }
      }

      if (val.monthField && val.monthField !== '' &&
      val.yearField && val.yearField !== '' &&
      val.yearField.length === 4 &&
      val.dayField && val.dayField !== '') {
        // check if bigger Than Today
        const validDate = this.validateDate(val.yearField + '-' + val.monthField + '-' + val.dayField);
        if (!validDate || !this.dateNotBiggerThanToday(val.yearField + '-' + val.monthField + '-' + val.dayField)) {
          this.setYear('');
        }
      }

      if (this.requiredField) {

          this.fieldForm.controls['monthField'].clearValidators();
          this.fieldForm.controls['monthField'].setValidators([
            Validators.required,
            ValidateMonth,
          ]);
          this.fieldForm.controls['monthField'].updateValueAndValidity({ emitEvent: false });

          this.fieldForm.controls['dayField'].clearValidators();
          this.fieldForm.controls['dayField'].setValidators([
            Validators.required,
            ValidateDay,
          ]);
          this.fieldForm.controls['dayField'].updateValueAndValidity({ emitEvent: false });

          this.fieldForm.controls['yearField'].clearValidators();
          this.fieldForm.controls['yearField'].setValidators([
            Validators.required,
            ValidateYear,
          ]);
          this.fieldForm.controls['yearField'].updateValueAndValidity({ emitEvent: false });

      } else if (!this.requiredField && val.monthField === '' && val.dayField === '' && val.yearField === '') {
          this.fieldForm.controls['monthField'].clearValidators();
          this.fieldForm.controls['monthField'].updateValueAndValidity({ emitEvent: false });
          this.fieldForm.controls['dayField'].clearValidators();
          this.fieldForm.controls['dayField'].updateValueAndValidity({ emitEvent: false });
          this.fieldForm.controls['yearField'].clearValidators();
          this.fieldForm.controls['yearField'].updateValueAndValidity({ emitEvent: false });
      } else if (!this.requiredField && (val.monthField !== '' || val.dayField !== '' || val.yearField !== '')) {
          this.fieldForm.controls['monthField'].clearValidators();
          this.fieldForm.controls['monthField'].setValidators([
            ValidateMonth,
          ]);
          this.fieldForm.controls['monthField'].updateValueAndValidity({ emitEvent: false });

          this.fieldForm.controls['dayField'].clearValidators();
          this.fieldForm.controls['dayField'].setValidators([
            ValidateDay,
          ]);
          this.fieldForm.controls['dayField'].updateValueAndValidity({ emitEvent: false });
          this.fieldForm.controls['yearField'].clearValidators();
          this.fieldForm.controls['yearField'].setValidators([
            ValidateYear,
          ]);
          this.fieldForm.controls['yearField'].updateValueAndValidity({ emitEvent: false });
        }
    });
    if (changes.getDob && changes.getDob.currentValue !== '' && changes.getDob.previousValue !== changes.getDob.currentValue) {
     // *(changes, 'dob',  new Date().getMilliseconds())
     const splitDate = changes.getDob.currentValue.split('T')[0]; // YYYY-MM-DD
      // test if valid date
      const testDate = this.validateDate(splitDate);

      const dateParts = splitDate.split('-');

      if (testDate && this.dateNotBiggerThanToday(splitDate) ) {
        setTimeout(() => {
          this.fieldForm.get('yearField').setValue(dateParts[0]);
          this.fieldForm.get('monthField').setValue(dateParts[1]);
          this.fieldForm.get('dayField').setValue(dateParts[2]);
        }, 100);
      } else {
        this.setYear('');
        this.setMonth('');
        this.setDay('');
      }
    }
  }

  clearDate() {
    this.setMonth('');
    this.setMonth('');
    this.setMonth('');
  }

  async setMonth(mnt: string) {
    await setTimeoutPromise(1).then(res => {
      this.fieldForm.get('monthField').setValue(mnt);
    });
  }

  async setDay(dy: string) {
    await setTimeoutPromise(1).then(res => {
      this.fieldForm.get('dayField').setValue(dy);
    });
  }

  async setYear(yr: string) {
    await setTimeoutPromise(1).then(res => {
      this.fieldForm.get('yearField').setValue(yr);
    });
  }

  focusInputDay (event: any): void {
    this.fieldForm.get('dayField').markAsTouched();
    this.fieldForm.get('dayField').markAsDirty();
    let total = 0;
    let container = null;
    const _rec = (obj: any) => {
        total += obj.offsetTop;
        const par = obj.offsetParent;
        if (par && par.localName !== 'ion-content') {
            _rec(par);
        } else {
            container = par;
        }
    };
    _rec(event.target);
    container.scrollToPoint(0, total - 50, 400);
  }

  focusInputYear (event: any): void {
    this.fieldForm.get('yearField').markAsTouched();
    this.fieldForm.get('yearField').markAsDirty();
    let total = 0;
    let container = null;
    const _rec = (obj: any) => {
        total += obj.offsetTop;
        const par = obj.offsetParent;
        if (par && par.localName !== 'ion-content') {
            _rec(par);
        } else {
            container = par;
        }
    };
    _rec(event.target);
    container.scrollToPoint(0, total - 50, 400);
  }

}
