import { Injectable } from '@angular/core';
import {Restangular} from 'ngx-restangular';
import {AppSettings} from '../../app.settings';
import {RadiusService} from '../radius/radius.service';
import {UsersService} from '../users/users.service';
import Handlebars from 'handlebars/dist/cjs/handlebars';
import {BrandsService} from '../brands/brands.service';
import {CryptoService} from '../crypto/crypto.service';
import {Platform} from '@ionic/angular';
import {ActivatedRoute, Router} from '@angular/router';
import {ToastComponent} from '../../components/toast/toast.component';
import { UiAlertService } from '../ui-alert/ui-alert.service';
import {StripeService} from '../stripe/stripe.service';
import {Location} from '@angular/common';
import {VendorsService} from '../vendors/vendors.service';
import {NotificationsService} from '../notifications/notifications.service';
import { CognitoUserPool, CognitoUser, AuthenticationDetails } from 'amazon-cognito-identity-js';
import {BrowserService} from '../browser/browser.service';
import {UtilsService} from '../utils/utils.service';

@Injectable({
  providedIn: 'root'
})
export class RewardsService {

  static Reward = {
    FIRST_GOAL: 'firstGoal',
    COMPLETE_FIRST_TASK: 'completeFirstTask',
    ASSIGN_TASK: 'assignTask',
    CONNECT_BANK_ACCOUNT: 'connectBankAccount',
    ADD_CHILD: 'addChild',
    HALFWAY_GOAL: 'halfwayGoal',
    COMPLETE_GOAL: 'completeGoal'
  };

  CACHING = false;

  private rewards;

  constructor(
      private restangular: Restangular,
      private radiusService: RadiusService,
      private brandsService: BrandsService,
      private platform: Platform,
      private cryptoService: CryptoService,
      private usersService: UsersService,
      private toastComponent: ToastComponent,
      private router: Router,
      private uiAlertService: UiAlertService,
      private stripeService: StripeService,
      private location: Location,
      private vendorsService: VendorsService,
      private notificationsService: NotificationsService,
      private browserService: BrowserService,
      private utilsService: UtilsService
  ) { }

  private rewardParams = {
    brand: this.brandsService.getCampaignBrand()
  };

  getRewards(userId: string, readFromCache: boolean = false, dontFilter: boolean = false): Promise<any> {
    if (readFromCache && this.rewards) {
      return Promise.resolve(this.rewards);
    }
    return this.restangular.one('v3').all('rewards').customGETLIST('', this.rewardParams).toPromise().then(rewards => {
      rewards = this.utilsService .uniqWith(rewards, (a: any, b: any) => a.id === b.id);
      if (!userId) {
        return rewards;
      }
      return dontFilter ? rewards : this.filterOutClaimedRewards(userId, rewards);
    });
  }

  getLocalRewards(userId: string, latitude: number, longitude: number): Promise<any> {
    return this.restangular.one('v3').one('rewards', userId).one('latitude', latitude).one('longitude', longitude).customGETLIST('', this.rewardParams).toPromise().then(rewards => {
      return this.utilsService.uniqWith(rewards, (a: any, b: any) => a.id === b.id);
    });
  }

  getPublicRewards(): Promise<any> {
    return this.restangular.one('v3').one('rewards').all('public').customGETLIST('', this.rewardParams).toPromise().then(rewards => {
        return this.utilsService.uniqWith(rewards, (a: any, b: any) => a.id === b.id);
    });
  }

  private filterOutClaimedRewards(userId, rewards): Promise<any> {
    // TODO: DO THIS ON THE SERVER SIDE
    return this.getEarnedRewards(userId).then(earnedRewards => {
      const result = rewards.filter(reward => (reward.schedule && +reward.schedule.count === 0) || !earnedRewards.find(earnedReward => earnedReward.rewardId === reward.id && earnedReward.status === 'claimed'));
      return result;
    });
  }

  getReward(userId: string, rewardId: string): Promise<any> {
    return this.getRewards(userId, true).then(rewards => {
      return rewards.find(thisReward => rewardId === thisReward.id);
    });
  }

  findReward(userId: string, rewardName: string): Promise<any> {

    if (!this.brandsService.getUIConfig().snacks) {
      return Promise.resolve(undefined);
    }

    return this.getRewards(userId,  true).then(rewards => {
      return this.filterOutClaimedRewards(userId, rewards).then(filteredRewards => {
        return filteredRewards.find(thisReward => rewardName === thisReward.name.split(' ')[0]);
      });
    });
  }

  presentReward(userId: string, rewardId: string): Promise<any> {
    return this.getRewards(userId, true).then(rewards => {
      const foundReward = rewards.find(thisReward => rewardId === thisReward.id);
      if (foundReward) {
        let amount;
        switch (foundReward.rewardDetails.amount.type) {
          case 'fixed':
            amount = foundReward.rewardDetails.amount.value;
            break;
          case 'randomLinear':
            amount = Math.random() * (foundReward.rewardDetails.amount.highValue -
                foundReward.rewardDetails.amount.lowValue) + foundReward.rewardDetails.amount.lowValue;
            if (amount.roundTo) {
              amount = Math.round(amount / foundReward.rewardDetails.amount.roundTo) * foundReward.rewardDetails.amount.roundTo;
            }
            break;
        }
        this.restangular.one('v3').one('rewards', userId).all('earned').customPOST({
          status: 'presented',
          parameters: { amount: amount },
          rewardId: foundReward.id
        });
      }
      return Promise.resolve(foundReward);
    });
  }

  getUserRewardCount(userId: string, rewardName: string): Promise<number> {
    return this.getRewards(userId,  true).then(rewards => {
      const foundReward = rewards.find(thisReward => rewardName === thisReward.name);
      if (foundReward) {
        return this.restangular.one('v3').one('user', userId).one('reward', foundReward.id).one('count').get().toPromise();
      }
      return Promise.resolve(-1);
    });
  }

  getGlobalRewardCount(userId: string, rewardName: string): Promise<number> {
    return this.getRewards(userId, true).then(rewards => {
      const foundReward = rewards.find(thisReward => rewardName === thisReward.name);
      if (foundReward) {
        return this.restangular.one('v3').one('reward', foundReward.id).one('count').get().toPromise();
      }
      return Promise.resolve(-1);
    });
  }

  addFormDataToReward(userId: string, rewardName: string, formData: any): Promise<any> {
    return this.getRewards(userId, true).then(rewards => {
      const foundReward = rewards.find(thisReward => rewardName === thisReward.name);
      if (foundReward) {
        this.restangular.one('v3').one('rewards', userId).all('earned').customPATCH({
          formData: formData,
          rewardId: foundReward.id
        });
      }
      return Promise.resolve(foundReward);
    });
  }

  claimReward(familyId: string, userId: string, rewardId: string): Promise<any> {
    return this.claimRewardHelper(familyId, userId, rewardId, {});
  }

  claimGoalReward(familyId: string, userId: string, rewardId: string, goalId: string): Promise<any> {
    return this.claimRewardHelper(familyId, userId, rewardId, {goalId: goalId});
  }

  claimTaskReward(familyId: string, userId: string, rewardId: string, taskId: string): Promise<any> {
    return this.claimRewardHelper(familyId, userId, rewardId, {taskId: taskId});
  }

  private claimRewardHelper(familyId: string, userId: string, rewardId: string, args: any): Promise<any> {
    return this.getRewards(userId,  false, true).then(rewards => {
      const foundReward = rewards.find(thisReward => rewardId === thisReward.id);
      if (foundReward) {
        const patchPayload = Object.assign({}, {
          status: 'claimed',
          rewardId: foundReward.id
        }, args);
        // TODO: THIS MUST BE DONE ON THE SERVER
        return this.restangular.one('v3').one('rewards', userId).all('earned').customPOST(patchPayload).then(info => {
          return this.radiusService.reloadAccount(familyId, userId, info.amount, AppSettings.getRewardsInfo().wallitCounterParty);
        });
      }
      return Promise.resolve(foundReward);
    });
  }

  getEarnedRewards(userId: string): Promise<any> {
    return this.restangular.one('v3').one('rewards', userId).all('earned').getList().toPromise().then(rewards => {
      return rewards;
    });
  }

  getRewardTransactions(familyId: string, userId: string): Promise<any> {
    // return this.restangular.one('families', familyId).one('users', userId).one('rewards').one('campaigns').all('transactions').getList().toPromise();
    return this.restangular.one('families', familyId).one('users', userId).one('rewards', 'x').one('available-campaigns').getList().toPromise();
  }

  claimRewardTransaction(familyId: string, userId: string, claimId: string): Promise<any> {
    const body = {
      claimed: true
    };
    return this.restangular.one('families', familyId).one('users', userId).one('rewards').one('campaigns').all('transactions', claimId).customPUT(body).toPromise();
  }

  getRewardActionFromStripeProductId(userId: string, productId: string): Promise<any> {
    return this.restangular.one('v3').one('rewards', userId).one('productid', productId).one('getpurchaseaction').get().toPromise();
  }

  getRewardFromStripeProductId(userId: string, productId: string): Promise<any> {
    return this.restangular.one('v3').one('rewards', userId).one('productid', productId).one('getreward').get().toPromise();
  }

  purchaseProduct(productId, count, totalPrice, action, doneCallback) {
    if (count === 0 || count === '0') {
      if (doneCallback) {
        doneCallback();
      }
      return;
    }
    this.stripeService.getUserPaymentInfo().then(userPaymentInfo => {
      if (!userPaymentInfo.defaultPaymentMethod) {
        this.uiAlertService.presentAlertConfirm(this.uiAlertService.paymentMessage()).then(confirm => {
          if (confirm) {
            this.router.navigate(['/products']);
          }
        });
        return;
      }
      this.uiAlertService.presentAlertConfirm(`This will initiate a recurring charge of $${totalPrice.toFixed(2)} for ${count} item${count !== 1 ? 's' : ''}.<p>You may cancel your subscription at any time from the Payment Methods & History area of the Products tab.</p>Proceed?`).then(confirm => {
        if (confirm) {
          this.stripeService.purchaseSubscription(userPaymentInfo.stripeCustomerId, productId, count, true).then(result => {
            if (!result.error && !result.errors) {
              this.toastComponent.presentToast('Your product has been purchased and your payment method charged.');
              setTimeout(() => {
                this.notificationsService.updateNotifications();
              }, 1000);
            }
            this.stripeService.notifyProductsPurchasedChanged(productId);
            if (doneCallback) {
              doneCallback();
            } else {
              this.location.back();
            }
          }).catch(error => this.toastComponent.presentToast('Your product purchase failed.'));
        }
      });
    });
  }

  gotoUrl(url, iFrameAllowed) {
    console.log('GOTOURL', url, iFrameAllowed)
    if (this.platform.is('cordova')) {
      this.browserService.open(url);
    } else {
      if (iFrameAllowed) {
        this.router.navigate(['embeddedpage', this.cryptoService.encodeJSON({url})]);
      } else {
        this.browserService.open(url);
        // window.location.href = url;
      }
    }
  };

  afterPurchase(action, offer= {}) {
    let template;
    switch (action.purchased?.destination.type) {
      case 'externalurl':
        template = Handlebars.compile(action.purchased.destination.value);
        window.open(template(Object.assign({}, this.usersService.me(), {reward: offer }, {platform: this.platform.platforms()})));
        return true;
      case 'embedded':
        template = Handlebars.compile(action.purchased.destination.value);
        console.log('GOTOURL 1')
        this.gotoUrl({url: template(this.usersService.me())}, true);
        return true;
      default:
        this.vendorsService.getProductLaunchMetadata(action.arguments.vendorProductId).then(launchInfo => {
          if (launchInfo.cognito) {
            const userPool = new CognitoUserPool(launchInfo.cognito);
            const authenticationDetails = new AuthenticationDetails({
              Username: launchInfo.user.name,
              Password: launchInfo.user.password,
            });
            const userData = {
              Username: launchInfo.user.name,
              Pool: userPool,
            };
            const cognitoUser = new CognitoUser(userData);
            cognitoUser.authenticateUser(authenticationDetails, {
              onSuccess: () => {
                setTimeout(() => {
                  console.log('GOTOURL 5');
                  this.gotoUrl(launchInfo.url, action.arguments.iframe);
                }, 200);
              },
              onFailure: () => {
                console.log('COGNITO AUTH FAIL');
              }
            });
          } else {
            console.log('GOTOURL 2', action.arguments.iframe);
            this.gotoUrl(launchInfo.url, action.arguments.iframe);
          }
        });
    }
    return false;
  }

}
