import { Injectable } from '@angular/core';
import {
    HttpEvent,
    HttpRequest,
    HttpResponse,
    HttpInterceptor,
    HttpHandler,
    HttpErrorResponse
} from '@angular/common/http';
import { tap, catchError } from 'rxjs/operators';
import { HttpCacheService } from '../services/http-cache/http-cache.service';
import {Observable, of, throwError} from 'rxjs';
import {Router} from '@angular/router';
import {ToastComponent} from '../components/toast/toast.component';

@Injectable({
    providedIn: 'root'
})
export class CachingInterceptor implements HttpInterceptor {
    constructor(private cache: HttpCacheService, private router: Router, private toast: ToastComponent) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<any> {
        const cachedResponse: HttpResponse<any> = this.cache.get(req);
        const twoFactorRequest: boolean = req.url.includes('2fa');
        return cachedResponse && !twoFactorRequest ? of(cachedResponse) : this.sendRequest(req, next, this.cache);
    }

    sendRequest(req: HttpRequest<any>, next: HttpHandler, cache: HttpCacheService): Observable<any> {
        return next.handle(req).pipe(
            tap((event: HttpEvent<any>): void => {
                if (event instanceof HttpResponse) {
                    cache.put(req, event);
                }
            }),
            catchError((error: HttpErrorResponse) => {
                if (error.status === 401 && error?.error?.error === 'Not trusted device!') {
                    const userEmail: string = req.body?.username;
                    if (userEmail) {
                        void this.router.navigate(['2fa/options', userEmail]);
                    }
                } else {
                    this.toast.presentError(error);
                }
                return throwError(() => error as unknown as HttpEvent<any>);
            })
        );
    }



}
